import React, { Component, Fragment } from "react";
//import L from 'leaflet';
import { connect } from "react-redux";
import { Link} from "react-router-dom";

//Actions
import { getSchachts, createSchacht, updateSchachtGeom, deleteSchacht } from "../../actions/schachtActions";
//Material UI
import { TableContainer, Table, TableBody, TableRow, TableCell, Button, StylesProvider, Paper, Dialog, DialogActions, DialogTitle, Divider } from "@material-ui/core";

import equal from 'fast-deep-equal'

// Karte
import { LayersControl, LayerGroup, Map, TileLayer, WMSTileLayer, CircleMarker, Popup, ZoomControl, FeatureGroup} from 'react-leaflet';
//import MarkerClusterGroup from "react-leaflet-markercluster";
import Control from 'react-leaflet-control';
import LocateControl from './LocateControl';

import { EditControl } from "react-leaflet-draw";
import drawLocal from './drawLocal';
import Spinner from "../layout/Spinner/Spinner";

// Style
import 'leaflet-draw/dist/leaflet.draw.css'
import 'react-leaflet-markercluster/dist/styles.min.css';
import './Map.css';



const { BaseLayer, Overlay } = LayersControl;

type Position = [number, number]

type Props = {|
  content: string,
  position: Position,
  radius: number,
|}

type MarkerData = {| ...Props, key: string |}

const MyPopupMarker = ({ content, position }: Props) => (
  <CircleMarker id={content.id} center={position} color={content.color} fillColor="white" fillOpacity={0.8} radius={8}>
    <Popup>
	  <h4><b>Schacht {content.designation}</b></h4>
	  <StylesProvider injectFirst>
	  <TableContainer className="tooltip-table-container" component={Paper}>
	    <Table>
		  <TableBody>
		    <TableRow className="tooltip-table-row">
			  <TableCell className="tooltip-table-head" component="th" scope="row">ID</TableCell>
			  <TableCell className="tooltip-table-cell" align="right">{content.id}</TableCell>
			</TableRow>
		    <TableRow>
			  <TableCell className="tooltip-table-head" component="th" scope="row">Name / Nummer</TableCell>
			  <TableCell className="tooltip-table-cell" align="right">{content.designation}</TableCell>
			</TableRow>
		    <TableRow>
			  <TableCell className="tooltip-table-head" component="th" scope="row">Adresse</TableCell>
			  <TableCell className="tooltip-table-cell" align="right"></TableCell>
			</TableRow>
		    <TableRow>
			  <TableCell className="tooltip-table-head" component="th" scope="row">Schachtprotokoll</TableCell>
			  <TableCell className="tooltip-table-cell" align="right">{content.schachtprotokoll}</TableCell>
			</TableRow>
	      </TableBody>
		</Table>
	  </TableContainer>
	  <a onClick={() => {window.location.href=`/workflow/${content.id}` }}>
	  <Button size="small" variant="contained">
	      Protokoll
        </Button>
	  </a>
	  </StylesProvider>
	</Popup>
  </CircleMarker>
)

const MyMarkersList = ({ markers }: { markers: Array<MarkerData> }) => {
  const items = obj.map(({ key, ...props }) => (
    <MyPopupMarker key={key} {...props} />
  ))
  return <Fragment>{items}</Fragment>
}

let obj = [];

class Karte extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
	  height: '',
	  viewport: {},
	  radius: 10,
	  isDialogOpen: false,
      project_id: null,
      object_id: null	  
	}
  }

  updateDimensions() {
    const height = window.innerHeight - 50
    this.setState({ height: height })
  }

  componentWillMount() {
    this.updateDimensions()
  }

  componentDidMount() {
    if (typeof this.props.projects.project.project_id == "undefined" && typeof this.props.projects.project.object_id == "undefined") {
      this.props.getSchachts(localStorage.getItem('project_id'), localStorage.getItem('object_id'));
	  this.setState({project_id: localStorage.getItem('project_id'), object_id: localStorage.getItem('object_id'), viewport: {center:[localStorage.getItem('start_lat'), localStorage.getItem('start_long')], zoom: localStorage.getItem('start_zoom')}});
	}
	else if (this.state.project_id === null && this.state.object_id === null) {
		this.props.getSchachts(this.props.projects.project.project_id, this.props.projects.project.object_id);
		this.setState({project_id: this.props.projects.project.project_id, object_id: this.props.projects.project.object_id, viewport: {center:[this.props.projects.project.start_lat, this.props.projects.project.start_long], zoom: this.props.projects.project.start_zoom}});
    }		
    window.addEventListener("resize", this.updateDimensions.bind(this))
	let hamburger = document.querySelector(".top-nav");
	if(hamburger) { hamburger.classList.remove("top-nav-invisibile"); }
  }
  
  componentDidUpdate(prevProps) {
	if(!equal(this.props.projects.project, prevProps.projects.project)) {
		this.props.getSchachts(this.props.projects.project.project_id, this.props.projects.project.object_id);
		this.setState({project_id: this.props.projects.project.project_id, object_id: this.props.projects.project.object_id, viewport: {center:[this.props.projects.project.start_lat, this.props.projects.project.start_long], zoom: this.props.projects.project.start_zoom}});
    }
  } 

  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }
  
  _onCreated = (e) => {
    let layer = e.layer;
	let NewSchacht = {
      designation: "NewSchacht",
      lat: layer._latlng.lat,
	  lng: layer._latlng.lng,
	  id_project: this.state.project_id,
	  id_object: this.state.object_id
    };
    this.props.createSchacht(NewSchacht);
    this.setState({
      isDialogOpen: true
    });
  }
  
  _onEdited = (e) => {
    let numEdited = 0;
    e.layers.eachLayer( (layer) => {
	  let EditData = {
        id: layer.options.id,
        lat: layer._latlng.lat,
	    lng: layer._latlng.lng
      };
	  console.log(EditData)
      this.props.updateSchachtGeom(EditData);
	  numEdited += 1;	
    });
    console.log(`_onEdited: edited ${numEdited} layers`, e);
  }
  
  
  _onDeleted = (e) => {
    let numDeleted = 0;
    let idData
    e.layers.eachLayer( (layer) => {
      idData = layer.options.id;
	  this.props.deleteSchacht(idData);
	  numDeleted += 1;	
    });
    console.log(`onDeleted: removed ${numDeleted} layers`, e);
  }

  handleDialogOK = () => {
    this.setState({
      isDialogOpen: false
    });
	this.props.history.push(`/workflow/${this.props.schachts.schacht.id}`)
  }
	
  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false
    });
	obj.push({
			key: this.props.schachts.schacht.id,
			position: [this.props.schachts.schacht.lat, this.props.schachts.schacht.lng],
			content: {
				id: this.props.schachts.schacht.id, 
				designation: this.props.schachts.schacht.designation,
				id_material: this.props.schachts.schacht.id_material,
				id_function: this.props.schachts.schacht.id_function
		}
	});
  }
  
  state = {obj}
 
  render() {
	const { schachts, schachtsLoading} = this.props.schachts;
	if (schachts.length > 0 && schachtsLoading === false) {
    obj.length = 0
	for(let i=0; i < schachts.length; i++) {
		let color
		let schachtprotokoll
	    if (schachts[i].protokoll_created === true ) {color = 'green'; schachtprotokoll = 'Ja';} else {color = 'blue'; schachtprotokoll = 'Nein';}
		if (schachts[i].lat || schachts[i].lng) {
		  obj.push({
		  	key: i,
		  	position: [schachts[i].lat, schachts[i].lng],
		  	content: {
		  		id: schachts[i].id, 
		  		designation: schachts[i].designation,
		  		id_material: schachts[i].id_material,
		  		id_function: schachts[i].id_function,
		  		schachtprotokoll: schachtprotokoll,
		  		color: color
		  	}
		  });
		}
    }
	}
	const schacht = {}
	
	let dialogCreated
	if (schacht === null || schacht.length === 0) {
      dialogCreated = <Spinner />;
    } else {
	   dialogCreated = (
        <>
	      <Dialog
		     disableBackdropClick
             disableEscapeKeyDown
             open={this.state.isDialogOpen}
             onClose={this.handleDialogClose}
             aria-labelledby="alert-dialog-title"
             aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Schacht (ID = {schacht.id}) erstellt</DialogTitle>
			<Divider />
              <DialogActions>
                <Button onClick={this.handleDialogClose} variant="contained" >
                  Zur Karte
                </Button>
                <Button onClick={this.handleDialogOK} variant="contained" color="primary">
                  Zum Protokoll
                </Button>
              </DialogActions>
          </Dialog>
        </>
      );
	}
    return (
    <div className="map-wrapper" style={{ height: this.state.height }}>
      <Map
	    preferCanvas={true}
		viewport={this.state.viewport}
		minZoom={15}
		maxZoom={21}
		zoomControl={false}>
      <LayersControl position="bottomright">
        <BaseLayer checked name="AV">
          <WMSTileLayer
		    layers='MBSF,LNNA,LOCPOS,HADR,SOPT,SOLI,SOSF,LCSF'
		    format='image/png'
            url="https://geodienste.ch/db/av_0?"
		    transparent='true'
			srs='EPSG:3857'
          />
		</BaseLayer>
	    <BaseLayer name="Orthofoto">
          <TileLayer
             url="https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg"
          />
		</BaseLayer>
      </LayersControl>
      <ZoomControl position="topright" />
	  <Control position="topleft" >
		<LocateControl /> 
      </Control>
	    <LayersControl position="topleft">
	  	  <Overlay checked name="Schacht">
		    <LayerGroup id="lg1">
	          <FeatureGroup >
                 <EditControl
                   position='topleft'
                   onEdited={this._onEdited}
                   onCreated={this._onCreated}
                   onDeleted={this._onDeleted}
                   draw={{
                     rectangle: false,
		             marker: false,
		             circle: false,
		             polyline: false,
		             polygon: false,
		             circlemarker: {
			           color: 'red',
				       radius: 12
			         }
                   }}
                />
                <MyMarkersList />
            </FeatureGroup>
	  	  </LayerGroup>
        </Overlay>
	  	<Overlay checked name="Lyss">
		 <WMSTileLayer
            layers='rsw_306_abw'
		    format='image/png'
            url="https://mapservice.rswag.ch/mapproxy/client/wms?"
		    transparent='true'
          />
        </Overlay>
      </LayersControl>
      </Map>
      <div>{dialogCreated}</div>
	  </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projects,						 
  schachts: state.schachts,
  schacht: state.schachts.schacht
});

export default
  connect(
    mapStateToProps,
    { getSchachts,
      createSchacht,
	  updateSchachtGeom,
      deleteSchacht
    }
  )(Karte);